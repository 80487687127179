import {
  Box,
  Grid,
  Link as ExternalLink,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link as InternalLink } from 'gatsby';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface ContainerCardProps {
  title: string;
  subtitle?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  link?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
  },
  titleFont: {
    fontFamily: 'Futura, Tw Cen MT',
    fontSize: '1.5rem',
    fontWeight: 300,
  },
}));

const StyledInternalLink = styled(InternalLink)`
  color: inherit;
  &:visited: {
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
`;

export const CardHeading: FunctionComponent<ContainerCardProps> = ({
  title,
  subtitle,
  startIcon,
  endIcon,
  link,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      {startIcon && (
        <Grid item>
          <Box pt={0.5} pr={1}>
            {startIcon}
          </Box>
        </Grid>
      )}
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.titleFont}>{title}</Typography>
            {subtitle ? (
              <Typography variant={'subtitle2'}>{subtitle}</Typography>
            ) : null}
          </Grid>
          {endIcon ? (
            <Grid item>
              <Box pt={0.5} pr={1}>
                {link && link.startsWith('http') && (
                  <ExternalLink href={link} target={'_blank'} classes={classes}>
                    {endIcon}
                  </ExternalLink>
                )}
                {link && !link.startsWith('http') && (
                  <StyledInternalLink to={link}>{endIcon}</StyledInternalLink>
                )}
                {!link && endIcon}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardHeading;
