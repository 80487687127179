import { AllTrackedCostsChartProps } from '../charts/AllTrackedCostsChart';
import { DocumentationFeedProps } from '../documentation-feed/DocumentationFeed';
import { MonthlyChargesProps } from '../monthly-cost/MonthlyCharges';
import { NewsFeedProps } from '../news-feed/NewsFeed';
import { PowerStateChartProps } from '../charts/PowerStateChart';
import { CurrentBillSnapshotProps } from '../current-bill-snapshot/CurrentBillSnapshot';
import { CapacityPlanningBarChartProps } from '../../components/charts/CapacityPlanningBarChart';
import { WorkloadCountChartProps } from '../../components/charts/WorkloadCountChart';

type DashboardMockData = {
  allTrackedCosts: Pick<AllTrackedCostsChartProps, 'data'>;
  articleList: NewsFeedProps['articles'];
  documentationList: DocumentationFeedProps['documentations'];
  monthlyCharges: Pick<MonthlyChargesProps, 'data'>;
  powerState: PowerStateChartProps['data'];
  currentBillSnapshot: CurrentBillSnapshotProps;
  capacityPlanningBarData: CapacityPlanningBarChartProps;
  workloadCounts: WorkloadCountChartProps;
};

const dt = new Date();

export const dashboardMockData: DashboardMockData = {
  allTrackedCosts: {
    data: [
      {
        id: 'vm',
        label: 'Virtual Machines',
        value: 463,
        color: '#E41A1B',
      },
      {
        id: 'sql-servers',
        label: 'SQL Servers',
        value: 122,
        color: '#377DB8',
      },
      {
        id: 'vpc',
        label: 'Virtual Networks (VPC)',
        value: 85,
        color: '#4DAF4A',
      },
      {
        id: 'nfs-storage',
        label: 'NFS Storage',
        value: 203,
        color: '#984EA3',
      },
      {
        id: 'other',
        label: 'Other',
        value: 114,
        color: '#FF7F00',
      },
    ],
  },
  articleList: [
    {
      title:
        'Cisco Announces Intent To Acquire BabbleLabs To Improve Video Meeting Experience',
      date: new Date(),
      url: 'babblelabs link',
    },
    {
      title: 'Cisco Webex Classrooms Unveiled For Education-Focused Partners',
      date: new Date(new Date().setHours(dt.getHours() - 15)),
      url: 'classrooms link',
    },
    {
      title:
        'Cisco : Tools to Help You Deliver A Machine Learning Platform And Address Skill Gaps',
      date: new Date(new Date().setHours(dt.getHours() - 24)),
      url: 'machine learning platform link',
    },
    {
      title:
        'HOW CONTENT PROTECTION MARKET WILL DOMINATE IN COMING YEARS? KEY PLAYERS: CISCO SYSTEMS, MICROSOFT, GOOGLE, ETC.',
      date: new Date(new Date().setHours(dt.getHours() - 26)),
      url: 'content protection link',
    },
  ],
  documentationList: [
    {
      title: 'API Reference',
      to:
        'https://endpointsportal.blue-ocean-dev.cloud.goog/docs/billing-espv2-service-uchpcuqolq-uc.a.run.app/1/introduction',
      external: true,
    },
    {
      title: 'Developer Documentation',
      to: 'https://gitlab.com/ignw1/internal/cisco/athena-documentation',
      external: true,
    },
    { title: 'Cisco : Tools Summary' },
    {
      title: 'CISCO SYSTEMS, MICROSOFT, GOOGLE, ETC.',
    },
  ],
  monthlyCharges: {
    data: [
      {
        month: 'SEP',
        amount: 1094,
      },
      {
        month: 'OCT',
        amount: 2203,
      },
      {
        month: 'NOV',
        amount: 2967,
      },
      {
        month: 'DEC',
        amount: 1835,
      },
      {
        month: 'JAN',
        amount: 1170,
      },
      {
        month: 'FEB',
        amount: 432,
      },
    ],
  },
  powerState: [
    {
      powerState: 'Powered On',
      numVms: 56,
      color: '#6BC76B',
    },
    {
      powerState: 'Powered Off',
      numVms: 10,
      color: '#c72626',
    },
    {
      powerState: 'Other',
      numVms: 1,
      color: '#FFCC00',
    },
  ],
  currentBillSnapshot: {
    customerName: 'ACME, Inc.',
    currentBill: 987,
    start: '2021-01-01T00:00:00Z',
    end: '2021-01-31T23:59:59Z',
    links: [
      {
        text: 'Pay Your Bill',
        to: 'https://ignw-test.chargebee.com/d/invoices/62',
        external: true,
      },
      {
        text: 'Get Support',
        to: 'http://support.cisco.com',
        external: true,
      },
      {
        text: 'Understand Your Costs',
        to: '/billing',
      },
    ],
  },
  capacityPlanningBarData: {
    data: [
      {
        id: 'CPU',
        curr: 67,
        currColor: '#ddcc59',
        avg: 50,
        max: 75,
        maxColor: '#ded6a2',
        freeColor: '#ded6a2',
        capacity: '55000 Mhz',
      },
      {
        id: 'MEMORY',
        curr: 35,
        currColor: '#6bc76b',
        avg: 40,
        max: 50,
        maxColor: '#b5e3b5',
        freeColor: '#b5e3b5',
        capacity: '512 GiB',
      },
      {
        id: 'STORAGE',
        curr: 10,
        currColor: '#7683d7',
        avg: 15,
        max: 35,
        maxColor: '#aab4d4',
        freeColor: '#aab4d4',
        capacity: '4.5 TiB',
      },
    ],
    height: 400,
  },
  workloadCounts: {
    height: 400,
    getVmList: async () => new Array(64),
    getContainerList: async () => new Array(32),
  },
};

export default dashboardMockData;
